<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-file-document</v-icon
                  >{{ $vuetify.lang.t("$vuetify.receipt") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col></v-col>
            </v-row>

            <v-row>
              <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.bankName')"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" lg="6" md="6" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.ReceivingAccount')"
                  outlined
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0" cols="12" lg="4" md="6" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.TotalAmount')"
                  outlined
                  clearable
                  suffix="0"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col class="py-0" cols="12" lg="4" md="6" sm="6">
                <!-- <v-text-field
                  :label="$vuetify.lang.t('$vuetify.currency')"
                  outlined
                  clearable
                ></v-text-field> -->
                <v-select
                  outlined
                  :label="$vuetify.lang.t('$vuetify.currency')"
                  :items="currency"
                >
                </v-select>
              </v-col>
              <v-col class="py-0" cols="12" lg="4" md="6" sm="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :label="$vuetify.lang.t('$vuetify.pleaseChoose')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="6" sm="6">
                <v-textarea
                  :label="$vuetify.lang.t('$vuetify.remarks')"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="6" sm="6">
                <p>
                  {{ $vuetify.lang.t("$vuetify.PaymentOrder") }}:
                  <v-btn outlined @click="handleDialogSelectOrderList">
                    <v-icon left dark> mdi-plus </v-icon>
                    {{ $vuetify.lang.t("$vuetify.PaymentTermMgr") }}
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.save")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { dialog: Boolean, myObj: Object, deleteObj: Object, currency: Array },
  data() {
    return {
      valid: false,
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
    };
  },
  methods: {
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.$refs.form.validate() == true
        ? this.$emit("handledata", this.myObj)
        : console.log("false");
    },
    handleDialogSelectOrderList(){
        this.$emit("selectPurchaseOrderList");
    }
  },
};
</script>